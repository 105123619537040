import { useSelector } from "react-redux";
import SectionTitle from "../components/SectionTitle";
import Card from "../components/Card";

import {
  A11y,
  Navigation,
  HashNavigation,
  Pagination,
  Scrollbar,
  Grid,
  Autoplay,
  Parallax,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/hash-navigation";
import { Link } from "react-router-dom";

const BestSelling = () => {
  const { items: data } = useSelector((state) => state.bestSell);
  return (
    <>
      {data.length > 0 && (
        <div className="wrapper">
          <div>
            <div className="header-section  mt-10 mb-5  flex justify-between items-center">
              <SectionTitle title={"Best Selling"} />

              <Link
                to={`/best-selling`}
                className=" underline underline-offset-2 font-semibold md:text-xl text-lg"
              >
                View All
              </Link>
            </div>
          </div>

          <Swiper
            modules={[
              Navigation,
              Pagination,
              Scrollbar,
              A11y,
              HashNavigation,
              Grid,
              Autoplay,
              Parallax,
            ]}
            spaceBetween={25}
            // slidesPerView={6}
            // navigation
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            hashNavigation
            scrollbar={{ draggable: true }}
            parallax={true}
            grabCursor={true}
            loop={true}
            // breakpoints={{
            //   0: {
            //     slidesPerView: 2,
            //     spaceBetween: 10,
            //   },

            //   425: {
            //     slidesPerView: 3,
            //     spaceBetween: 25,
            //   },
            //   768: {
            //     slidesPerView: 4,
            //     spaceBetween: 25,
            //   },
            //   1024: {
            //     slidesPerView: 5,
            //     spaceBetween: 25,
            //   },
            //   1440: {
            //     slidesPerView: 6,
            //     spaceBetween: 25,
            //   },
            // }}
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 10,
              },

              330: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 25,
              },

              1024: {
                slidesPerView: 5,
                spaceBetween: 25,
              },
              1440: {
                slidesPerView: 5,
                spaceBetween: 25,
              },
            }}
          >
            {data?.length > 0 &&
              data.map((product) => (
                <SwiperSlide key={product.id}>
                  <Card key={product.id} product={product} />
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      )}
    </>
  );
};

export default BestSelling;
